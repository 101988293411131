<template>
  <div class="slider">
    <div :class="limiter ? 'slider__limiter' : ''"
         class="slider__wrapper"
         v-touch:swipe.left="nextSlide"
         v-touch:swipe.right="previewSlide"
         ref="sliderWrapper">
      <Row :style="{transform: `translateX(${slides.left}px)`}"
           class="slider__slides"
           ref="sliderRow">
        <component :is="component"
                   :key="item.id"
                   :params="item"
                   :style="{width: `${slide.width}px`,
                            height: height ? `${height}px` : `auto`,
                            margin: `0 ${indentation}px 0 0`}"
                   v-for="item in items"/>
      </Row>
      <div class="slider__navigation-button slider__preview"
           v-if="$laptop && current !== (items.length * -1) + count
           && items.length > count"
           v-on:click="nextSlide">
        <Icon class="slider__navigation-button-svg"
              viewport="0 0 25 18"
              xlink="arrow-slider-next"/>
      </div>
      <div class="slider__navigation-button slider__next"
           v-if="$laptop && current < 0
           && items.length > count"
           v-on:click="previewSlide">
        <Icon class="slider__navigation-button-svg"
              viewport="0 0 25 18"
              xlink="arrow-slider-preview"/>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Slider',
  props: {
    limiter: {
      type: Boolean,
    },
    indentation: {
      default: 30,
    },
    count: {
      default: 3,
    },
    items: {
      type: Array,
      required: true,
    },
    component: {
      type: String,
      required: true,
    },
    width: {},
    height: {},
  },
  data() {
    return {
      slide: {
        width: 0,
        height: 0,
      },
      slides: {
        left: 0,
      },
      wrapperWidth: 0,
      current: 0,
    };
  },
  mounted() {
    this.wrapperWidth = this.$refs.sliderWrapper.clientWidth;
    this.setSlideSize();
  },
  updated() {
    this.wrapperWidth = this.$refs.sliderWrapper.clientWidth;
    this.setSlideSize();
  },
  methods: {
    setSlideSize() {
      this.slide.width = this?.width || this.$mobile ? 288
        : (this.wrapperWidth - ((this.count - 1) * this.indentation)) / this.count;
      this.slide.height = this.$refs.sliderWrapper.children[0].clientHeight;
    },
    setSlidesPosition() {
      this.slides.left = (this.slide.width + this.indentation) * this.current;
    },
    nextSlide() {
      if (this.current > this.items.length * -1 + 1) {
        this.current -= 1;
        this.setSlidesPosition();
      }
    },
    previewSlide() {
      if (this.current < 0) {
        this.current += 1;
        this.setSlidesPosition();
      }
    },
  },
  watch: {
    $viewport() {
      this.wrapperWidth = this.$refs.sliderWrapper.clientWidth;
      this.setSlideSize();
    },
  },
};
</script>
